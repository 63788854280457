<template>
  <div id="pastPaper">
    <div class="container-fluid">
      <div class="row pt-2">
        <div class="col-sm-12">
          <h4 class="bg-secondary text-white text-start rounded p-1 ps-2">{{siteTitle}} Past Question Papers</h4>
          <div class="table-responsive">
            <table class="table caption-top table-striped text-start">
              <thead>
              <tr>
                <th class="text-center" style="width: 50px">Sl</th>
                <th>Event</th>
                <th>Round</th>
                <th>Category</th>
                <th class="text-center" style="width: 100px">Link</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(pastPaper,key) in pastPapers" :key="key">
                <td class="text-center">{{key+1}}</td>
                <td>{{pastPaper.event}}</td>
                <td>{{pastPaper.round}}</td>
                <td>{{pastPaper.category}}</td>
                <td><a target="_blank" download :href="pastPaper.file">Download</a></td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "PastPaper",
    created(){},
    computed:{
      siteTitle(){return this.$store.getters.getSiteTitle;},
      pastPapers(){return this.$store.getters.getPastPapers;},
    },
  }
</script>

<style scoped>

</style>